

import RcTableViewUtils from "../../../../../../shared/table/RcTableViewUtils";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow";
import SpacerDisplayRow from '@/portals/shared/cell/spacer/SpacerDisplayRow';
// import EmailUpdateRow from '@/portals/shared/cell/email/EmailUpdateRow';
// import PhoneNumberUpdateRow from '@/portals/shared/cell/phonenumber/PhoneNumberUpdateRow';
import JurisdictionUpdateRow from "@/portals/shared/cell/vehicle/jurisdiction/JurisdictionUpdateRow.js";
import CountryUpdateRow from "@/portals/shared/cell/country/CountryUpdateRow";
import LoadingDisplayRow from "@/portals/shared/cell/loading/LoadingDisplayRow";

import Address from "@/domain/model/address/Address.js"
// import Card from "@/domain/model/invoice/Card.js"

import ContentUtils from '@/utils/ContentUtils.js';
import MC from "@/domain/session/MC.js";

export default class CardUpdateViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
  }

  start() {
    super.start();
    if (this.model().isStatePageLoading()) {
      return;
    }

    if (this.model().isStateContentLoading()) {
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.buildFormHeader();
      this.buildFormBody();
      this.buildFormFooter();
      return;
    }

    if (this.model().state() === "saving") {
      this.buildFormSaving();
    }
  } 

  buildFormSaving() {
    this.add(new LoadingDisplayRow());
    this.add(new StringDisplayRow().withValue("Saving"));
  }

  buildFormHeader() {
    this.addHeader(new TitleDisplayRow().withTitle("Update Payment Details"));
    var error = this.model().error();
    if (error) {
      this.addHeader(new StringDisplayRow().withValue(error.hint)
        .withStyleEven()
        .withStyle("rc-title-error-left rc-font-regular-large")
        );
    }
  }

  buildFormBody() {
    this.add(new BoxDisplayRow().withChild(new RowDisplayRow()
          .withChild(new SpacerDisplayRow().withWidth("2"))
          .withChild(new BoxDisplayRow().withBorder(true)
                .withWidth("11")
                .withChild(new TitleDisplayRow().withTitle("Card Details").withSubtitle(true))
                .withChild(new StringUpdateRow()
                              .withMandatoryValue(true)
                              .withDefaultValue(this.model().card().name())
                              .withName("Name on Card")
                              .withField("ccName")
                              .withKey("Card"))
                .withChild(new StringUpdateRow()
                              .withMandatoryValue(true)
                              .withDefaultValue(this.model().card().number())
                              .withName("Card #")
                              .withField("ccNumber")
                              .withKey("Card"))
                .withChild(new StringUpdateRow()
                              .withMandatoryValue(true)
                              .withDefaultValue(this.model().card().cvc())
                              .withName("Card CVC #")
                              .withField("ccCvc")
                              .withKey("Card"))
                .withChild(new StringListUpdateRow()
                              .withOptions(ContentUtils.MONTHS())
                              .withMandatoryValue(true)
                              .withDefaultValue(this.model().card().month())
                              .withName("Expiry Month")
                              .withField("ccMonth")
                              .withKey("Card"))
                .withChild(new StringListUpdateRow()
                              .withOptions(ContentUtils.YEARS())
                              .withMandatoryValue(true)
                              .withDefaultValue(this.model().card().year())
                              .withName("Expiry Year")
                              .withField("ccYear")
                              .withKey("Card"))
            )
            .withChild(new SpacerDisplayRow().withWidth("2"))
            )
          );

    this.add(new BoxDisplayRow().withChild(new RowDisplayRow()
          .withChild(new SpacerDisplayRow().withWidth("2"))
          .withChild(new BoxDisplayRow().withBorder(true)
                .withWidth("11")
                .withChild(new TitleDisplayRow().withTitle("Card Holders Address").withSubtitle(true))
                .withChild(new StringUpdateRow()
                              .withMandatoryValue(true)
                              .withDefaultValue(this.model().address().street())
                              .withName("Street")
                              .withField(Address.FIELDS.STREET)
                              .withKey("Address"))
                .withChild(new StringUpdateRow()
                              .withDefaultValue(this.model().address().city())
                              .withMandatoryValue(true)
                              .withName("City")
                              .withField(Address.FIELDS.CITY)
                              .withKey("Address"))
                .withChild(new JurisdictionUpdateRow()
                              .withDefaultValue(this.model().address().province())
                              .withName("Province")
                              .withField(Address.FIELDS.JURISDICTION)
                              .withKey("Address")
                              .withMandatoryValue(true))
                .withChild(new CountryUpdateRow()
                              .withDefaultValue(this.model().address().country())
                              .withName("Country")
                              .withField(Address.FIELDS.COUNTRY)
                              .withKey("Address")
                              .withMandatoryValue(true))
                .withChild(new StringUpdateRow()
                              .withDefaultValue(this.model().address().postal())
                              .withMandatoryValue(true)
                              .withName("Postal Code")
                              .withField(Address.FIELDS.POSTAL)
                              .withKey("Address"))
          )
          .withChild(new SpacerDisplayRow().withWidth("2"))
          )
        );

  }

  buildFormFooter() {
    this.addFooter(new BoxDisplayRow()
          .withBorder(true)
          .withChild(
            new RowDisplayRow()
              .withChild(new StringDisplayRow().withWidth("3"))
              .withChild(new ButtonDisplayRow().withWidth("3").withName("Cancel").withField("DetailsClose"))
              .withChild(new StringDisplayRow().withWidth("3"))
              .withChild(new ButtonDisplayRow().withWidth("3").withName("Save").withField("Save").withBackgroundPrimaryAction())
              .withChild(new StringDisplayRow().withWidth("3"))
          )
      );
  }
}