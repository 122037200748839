
import RcTableViewModel from "../../../../../../shared/table/RcTableViewModel";
import DetailsDisplayViewUtils from "./BillingUpdateViewUtils";

import StringUtils from '@/utils/StringUtils.js';

export default class BillingUpdateViewModel extends RcTableViewModel {
    
  constructor(panel, settingsId) {
    super(panel);
    this._total = 0;
    this._error = null;
    this._items = [];
    this._settingsId = settingsId;
    this._settings = null;
    this._address = null;
  }

  start() {
    super.start();
  }

  settings() {
    return this._settings;
  }

  address() {
    return this._address;
  }

  isAdmin() {
    return this.panel().isAdmin;
  }

  withValue(valueData) {
    super.withValue(valueData);
    if (valueData["key"] === "InvoiceSettings") {
      this._settings.put(valueData["field"], valueData["value"])
    }
    if (valueData["key"] === "Address") {
      this._address.put(valueData["field"], valueData["value"])
    }
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }

    if (buttonData["field"] === "Save") {
      this.save();
    }
  }

  buildRowData(tableData) {
    var utils = new DetailsDisplayViewUtils(this, tableData);
    utils.start();
    return this;
  }

  error() {
    return this._error;
  }

  loadItems() {
    this._settings = this.domain().invoices().settings()
      .findById(this._settingsId).copy();
    this._address = this._settings.address().find();

    this
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
  }

  areRequirementsMet() {
    var met = true;
    var settings = this._settings;
    if (StringUtils.isEmpty(settings.contactName())) {
      met = false;
    }
    if (met && StringUtils.isEmpty(settings.companyName())) {
      met = false;
    }
    if (met && StringUtils.isEmpty(settings.contactEmail())) {
      met = false;
    }
    if (met && StringUtils.isEmpty(settings.contactPhone())) {
      met = false;
    }
    if (met && StringUtils.isEmpty(settings.emailTo())) {
      met = false;
    }
    if (met && StringUtils.isEmpty(settings.companyName())) {
      met = false;
    }

    var address = this._address;
    if (met && StringUtils.isEmpty(address.street())) {
      met = false;
    }
    if (met && StringUtils.isEmpty(address.city())) {
      met = false;
    }
    if (met && StringUtils.isEmpty(address.country())) {
      met = false;
    }
    if (met && StringUtils.isEmpty(address.province())) {
      met = false;
    }
    if (met && StringUtils.isEmpty(address.postal())) {
      met = false;
    }
    return met;
  }

  save() {
    const addressData = this._address.data;
    const settingsData = this._settings.data;

    if (!this.areRequirementsMet()) {
      this._showMissingRequirements = true;
      return;
    }

    var event = this.domain().events().invoice()
      .saveSettings(settingsData, addressData);

    this
      .withState("saving")
      .clearAndBuildTableData()
      .done();

    var context = {};
    context["model"] = this;
    if (!event) {
      return;
    }
    event.send(this.saveListener, context);
  }

  saveListener(response, context) {
    const model = context.model;
    if (response.hasError()) {
      //
    } else {
      if (model) {
        var buttonData = {};
        buttonData["field"] = "DetailsClose";
        buttonData["action"] = "reload";
        model.panel().withButtonPressed(buttonData);
      }
    }
  }
}